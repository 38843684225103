<template>
  <div class="l-contents">
    <h1 class="logo"> 
      <ion-img src="assets/logo.png"></ion-img> 
    </h1>
    <ion-item>
      <ion-label position="floating">{{$t('loginIdText')}}</ion-label>
      <ion-input v-model="loginForm.username"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">{{$t('loginPwText')}}</ion-label>
      <ion-input type="password" v-model="loginForm.password"></ion-input>
    </ion-item>

    <ion-button color="success" expand="full" @click="handleLogin">{{$t('loginText')}}</ion-button>
  </div>
</template>

<script>
import { 
  IonButton, 
  IonItem,
  IonLabel,
  IonInput
} from '@ionic/vue'
import { defineComponent, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { logIn, personAdd } from 'ionicons/icons'
//import { decrypt, encrypt } from "@/utils/jsencrypt"
import Cookies from "js-cookie"
import md5 from "js-md5"
import { useStore } from 'vuex'
import { addData } from '@/api/board/common_v2'

export default defineComponent({
  components: {
    IonButton, 
    IonItem,
    IonLabel,
    IonInput
  },
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()

    // store state
    const token = computed(() => store.state.User.token)
    
    // store actions
    const fetchLogin = () => store.dispatch('User/fetchLogin', param)

    let loginForm = reactive({
      username: undefined,
      password: undefined,
      //rememberMe: undefined
    })

    let param = reactive({})

    const setClose = () => {
      emit('close', false)
    }

    // 로그인
    const handleLogin = async () => {

      if(loginForm.username == '' || loginForm.password == '' || loginForm.username === undefined ||  loginForm.password === undefined){
        alert('Login failed')
        return
      }

      const apiUrl = 'api/qbuyer/manager'
      const payload = {
        member_info : {
          mem_id : loginForm.username,
          mem_pwd : btoa(loginForm.password)
        }
      }

      await addData(apiUrl, payload).then(response => {
          if(response.status == '200'){
            Cookies.set("memId", response.data.mem_id)
            Cookies.set("memNum", response.data.mem_num)
            router.push("/mobile/checkup")
          }else{
            alert('Login failed')
          }
      })

      //alert(loginForm.username);
      //Cookies.set("username", loginForm.username)
      /*
      if (loginForm.rememberMe) {
        Cookies.set("username", loginForm.username, { expires: 30 })
        Cookies.set("password", encrypt(loginForm.password), { expires: 30 })
        Cookies.set("rememberMe", loginForm.rememberMe, { expires: 30 })
      } else {
        Cookies.remove("username")
        Cookies.remove("password")
        Cookies.remove("rememberMe")
      }

      param = Object.assign({}, loginForm)
      param.password = md5(param.password)
      await fetchLogin(param)
      
      if (token.value) {
        router.push("/")
      }
      */
    }
    
    const getCookie = () => {
      const username = Cookies.get("username")
      //const password = Cookies.get("password")
      //const rememberMe = Cookies.get("rememberMe")
      loginForm = {
        username: username === undefined ? loginForm.username : username,
        //password: password === undefined ? loginForm.password : decrypt(password),
        //rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      }
    }

    return {
      logIn,
      personAdd,
      md5,
      loginForm,
      setClose,
      handleLogin,
      getCookie,

      // store state
      token,

      // store actions
      fetchLogin
    }
  }
})
</script>

<style scoped>
 .l-contents{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  padding: 20px ;
  transform: translate(-50%,-50%);
 }
 .logo{
  width: 150px;
  margin: 0 auto 20px;
 }
 :deep .button{
  margin: 30px 0 0;
 }
:deep .item-label {
  margin-bottom: 10px;
 }
 :deep ion-item {
   --padding-start: 0;
 }
 .item-interactive.item-has-focus {
  --highlight-background: var(--ion-color-success);
 }
 .item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
  color: var(--ion-color-success)
 }
</style>